// Dashboard.js
import React, { useState } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SideBar from "./SideBar";
import Profile from "./Profile";
import Courses from "./Courses";
import Apps from "./Apps";
import ChatBot from "./ChatBot";
import Widgets from "./Widgets";
import Stream from "./Stream";
import Landing from "../beATutor/Landing";
import Notes from "./Notes";
import Vocabuluary from "./Vocabuluary";
import VoiceAssistant from "./VoiceAssistant";

// Custom hook for styling components using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    backgroundColor: "#24303F", 
    color: "#BDC4CE",
  },
  mainContent: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (props) => (props.open ? theme.spacing(15) : theme.spacing(0)),
    width: (props) => `calc(100% - ${props.open ? 228 : 64}px)`,
    backgroundColor: "#1A222C",
    color: "#BDC4CE",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#24303F",
  },
}));

// Component displayed while loading is in progress
const LoadingComponent = () => (
  <Box className={useStyles().loadingContainer}>
    <CircularProgress />
  </Box>
);

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const [sidebarOpen, setSidebarOpen] = useState(true);

  // Apply styles with dynamic props for sidebar state
  const classes = useStyles({ open: sidebarOpen });

  // Handle loading state
  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  // Render the content based on the selected item in the sidebar
  const renderContent = () => {
    switch (selectedItem) {
      case "Profile":
        return <Profile onBeATutorClick={() => setSelectedItem("Be A Tutor")} />;
      case "Courses":
        return <Courses />;
      case "Stream":
        return <Stream />;
      case "Playground":
        return <ChatBot />;
      case "Apps":
        return <Apps />;
      case "Be A Tutor":
        return <Landing />;
      case "Notes":
        return <Notes />;
      case "Vocabuluary":
        return <Vocabuluary />;
      case "Voice Assistant":
        return <VoiceAssistant />;
      default:
        return <Widgets />;
    }
  };

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Container maxWidth="lg" className={classes.container}>
          <SideBar
            selectedItem={selectedItem}
            onItemSelected={(item) => setSelectedItem(item)}
            onTypographyClick={handleLoading}
            open={sidebarOpen}
            setOpen={setSidebarOpen}
          />
          <Grid container spacing={2} className={classes.mainContent}>
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
